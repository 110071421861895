<template>
  <Modal v-model="showDetail" footer-hide @on-cancel="close">
    <vueScroll :ops="ops">
      <div class="preview question-image-size-restrict">
        <span style="font-size: 1.2em" v-html="'[' + questionsType + ']' + questionStem"></span>
        <div v-if="questionsType === '单选题' || questionsType === '多选题'">
          <div v-for="(option, index) in questionOptions" :key="option.id">
            <span
              style="font-size: 1.1em"
              v-html="String.fromCharCode(index + 65) + '.' + questionOptions[index].content.replace(/\<\/?p(?!re)([^>]+)?\>/g, ' ')"
            ></span>
          </div>
          <br />
          <Alert type="success">
            答案：
            <template slot="desc">
              <span v-for="(option, index) in questionOptions" :key="option.id"
                ><span v-if="questionOptions[index].correct === true">{{ String.fromCharCode(index + 65) }}&nbsp;</span></span
              >
            </template>
          </Alert>
        </div>
        <div v-if="questionsType === '填空题'" style="display: flex">
          <br />
          <Alert type="success">
            答案：
            <template slot="desc">
              <div v-for="(blank, index) in questionBlanks" :key="blank">{{ index + 1 }}.{{ questionBlanks[index] }}</div>
            </template>
          </Alert>
        </div>
        <div v-if="questionsType === '简答题'">
          <br />
          <Alert type="success" v-if="questionAnswer !== ''" style="overflow: auto">
            答案：
            <template slot="desc">
              <div v-html="questionAnswer"></div>
            </template>
          </Alert>
        </div>
        <div v-if="questionsType === '代码题'">
          <br />
          <Alert type="success" v-if="template !== ''">
            模版：
            <template slot="desc">
              <pre v-html="template" style="white-space: pre-wrap;word-break: break-word;"></pre>
            </template>
          </Alert>
        </div>
        <Alert v-if="questionAnalysis !== ''">
          解析：
          <template slot="desc">
            <pre v-html="questionAnalysis" style="white-space: pre-wrap;word-break: break-word;"></pre>
          </template>
        </Alert>
        <Alert v-if="knowledge">
          知识点：
          <template slot="desc">
            <div v-for="(k, index) in knowledge" :key="index">
              {{ k.knowledgeName }}
            </div>
          </template>
        </Alert>
      </div>
    </vueScroll>
  </Modal>
</template>

<script>
import questionApi from '../../../../../api/questionRepo'
import vueScroll from 'vuescroll'
export default {
  components: { vueScroll },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      ops: {
        vuescroll: {},
        scrollPanel: {
          speed: 400
        },
        rail: {
          // size: '1px' // 滚动条大小
        },
        bar: {
          background: '#d2d2d2',
          opacity: 0.6 // 滚动条透明度
        }
      },
      showDetail: false,
      questionsType: '',
      questionBlanks: [],
      questionOptions: [],
      questionStem: '',
      questionAnalysis: '',
      questionAnswer: '',
      template: '',
      loading: false,
      knowledge: []
    }
  },
  methods: {
    getData(index) {
      this.loading = true
      this.questionsType = ''
      this.questionBlanks = []
      this.questionOptions = []
      this.questionStem = ''
      this.questionAnalysis = ''
      this.questionAnswer = ''
      let singleChoiceOptions = []
      let blankAnswer = []
      questionApi.getQuestionInfo(index).then(res => {
        this.questionStem = res.data.res.stem
        this.questionAnswer = res.data.res.answer
        this.questionAnalysis = res.data.res.analysis
        this.knowledge = res.data.res.knowledgePoints
        if (res.data.res.questionType === 1 || res.data.res.questionType === 2) {
          for (let i = 0; i < res.data.res.options.length; i++) {
            singleChoiceOptions.push(res.data.res.options[i])
          }
          this.questionOptions = singleChoiceOptions
        }
        if (res.data.res.questionType === 3) {
          for (let i = 0; i < res.data.res.blanks.length; i++) {
            blankAnswer.push(res.data.res.blanks[i].blankAnswer.join(','))
          }
          this.questionBlanks = blankAnswer
        }
        if (res.data.res.questionType === 5) {
          this.template = res.data.res.template.replace(/\n/g, '<br/>')
        }
        switch (res.data.res.questionType) {
          case 0:
            this.questionsType = '判断题'
            break
          case 1:
            this.questionsType = '单选题'
            break
          case 2:
            this.questionsType = '多选题'
            break
          case 3:
            this.questionsType = '填空题'
            break
          case 4:
            this.questionsType = '简答题'
            break
          case 5:
            this.questionsType = '代码题'
            break
        }
        this.loading = false
        this.showDetail = this.value
      })
    },
    close() {
      this.showDetail = false
      this.$emit('changeShowDetail', false)
      this.$emit('on-cancel')
    }
  }
  // watch: {
  //   value(val) {
  //     this.showDetail = val
  //   }
  // }
}
</script>

<style scoped lang="less">
::v-deep .ivu-modal .ivu-modal-body {
  padding: 0;
}
.preview {
  width: 100%;
  padding: 20px 50px;
  max-height: 600px;
  text-align: left;
}
::v-deep .ivu-alert {
  padding: 4px 10px;
  width: 100%;
}
.answer {
  float: left;
  font-size: 14px;
  font-weight: bolder;
}

.analysis {
  float: left;
  font-size: 14px;
  font-weight: bolder;
}
</style>
