<template>
  <div class="question-container">
    <Breadcrumb class="custom-breadcrumb">
      <BreadcrumbItem>题库管理</BreadcrumbItem>
      <BreadcrumbItem :to="{ path: '/question/bank' }">题库</BreadcrumbItem>
      <BreadcrumbItem v-if="$route.query['tabLabel']">{{ $route.query['tabLabel'] }}</BreadcrumbItem>
    </Breadcrumb>
    <div class="search-container">
      <div class="top-btnContainer">
        <Button class="filter-btn btn-shadow" type="primary" @click="addNewQuestion">
          <svg class="icon spread" aria-hidden="true">
            <use xlink:href="#icon-xinzeng" />
          </svg>
          新建题目
        </Button>
        <Button class="filter-btn btn-shadow" type="primary" @click="deleteQuestions" v-if="!showGrid || !showMore">
          <svg class="icon spread" aria-hidden="true">
            <use xlink:href="#icon-shanchu" />
          </svg>
          删除
        </Button>
        <Button class="filter-btn btn-shadow" type="primary" @click="moveQuestion" v-if="currentTab === 'my' && fromSearch === false">
          <svg class="icon spread" aria-hidden="true">
            <use xlink:href="#icon-move" />
          </svg>
          移动到题库
        </Button>
        <Dropdown @on-click="inputOutputQuestion" v-if="currentTab === 'my' && fromSearch === false">
          <Button type="primary" class="filter-btn btn-shadow">
            导入
            <!--            <svg class="icon spread" aria-hidden="true" style="font-size: 14px">-->
            <!--              <use xlink:href="#icon-bottom"></use>-->
            <!--            </svg>-->
          </Button>
          <DropdownMenu slot="list">
            <DropdownItem name="input">导入</DropdownItem>
            <!--            <DropdownItem name="output">导出</DropdownItem>-->
          </DropdownMenu>
        </Dropdown>
        <Button style="margin-right: auto" type="primary" @click="addToBank" v-if="currentTab === 'open' || currentTab === 'collected'">
          <svg class="icon spread" aria-hidden="true">
            <use xlink:href="#icon-xinzeng"></use>
          </svg>
          添加到题库
        </Button>
        <Button @click="showSetK" type="primary" class="filter-btn btn-shadow" v-if="!showGrid || !showMore">
          <div style="display: flex;align-items: center">
            <img src="../../../../assets/设置@2x.png" width="14" height="16" style="margin-right: 4px" />
            <div>设置知识点</div>
          </div>
        </Button>
      </div>
      <div style="display: flex;align-items: center">
        <Input
          class="search-bar"
          search
          placeholder="请输入关键字"
          @on-search="clearSearchQuestion()"
          v-model="keyWord"
          v-if="fromSearch === false"
        />
        <!-- ******************************* 高级搜索 ******************************* -->
        <div class="search-advanced">
          <div class="advanced-container" @click="isSpread = !isSpread">
            <span>高级搜索</span>
            <svg class="icon" :class="{ spread: isSpread }" aria-hidden="true">
              <use xlink:href="#icon-bottom" />
            </svg>
          </div>
          <div class="advanced-board bg-shadow" :class="{ show: isSpread, unshow: !isSpread }">
            <Form ref="searchForm" class="form" :model="searchFormItem" :label-width="85">
              <FormItem label="关键字" prop="keyWord">
                <Input v-model="searchFormItem.keyWord" placeholder="请输入" />
              </FormItem>
              <FormItem label="标签" prop="tag">
                <Select v-model="searchFormItem.questionTag" filterable multiple placeholder="请选择...">
                  <Option v-for="(tag, key, tIndex) in tags" :key="tIndex" :value="tag.tagId">
                    {{ tag.name }}
                  </Option>
                </Select>
              </FormItem>
              <FormItem label="题型" prop="questionType">
                <Select v-model="searchFormItem.questionType" placeholder="请选择...">
                  <Option v-for="(item, key, index) in questionType" :key="index + 0" :value="`${key}`"> {{ index + 1 }}.{{ item.label }} </Option>
                </Select>
              </FormItem>
              <FormItem label="难度" prop="difficulty">
                <Select v-model="searchFormItem.difficulty" placeholder="请选择...">
                  <Option v-for="(item, key, index) in difficulty" :key="index + 0" :value="`${key}`"> {{ index + 1 }}.{{ item.label }} </Option>
                </Select>
              </FormItem>
              <FormItem label="知识点" prop="knowledgePoints">
                <!--                <Select v-model="searchFormItem.knowledgePoints" multiple placeholder="请选择...">-->
                <!--                  <Option v-for="(item,index) in knowledgePoint" :key="index+0" :value="item.id">-->
                <!--                    {{index+1}}.{{item.name}}-->
                <!--                  </Option>-->
                <!--                </Select>-->
                <div class="kInput" @click="showDraw">
                  <Tag
                    style="margin: 0 8px 0 0"
                    @on-close="closeTag(index, true)"
                    color="#E7EFF7"
                    v-for="(item, index) in searchFormItem.knowledgePoints"
                    :key="index"
                    closable
                    >{{ item.name }}</Tag
                  ><br />
                  <Button
                    @click.stop="clearTag(true)"
                    v-if="searchFormItem.knowledgePoints ? searchFormItem.knowledgePoints.length !== 0 : false"
                    shape="circle"
                    style="width: 56px;height: 22px;line-height: 22px;color: #5E6470;font-size: 12px"
                    >清空</Button
                  >
                </div>
              </FormItem>
              <FormItem label="筛选" prop="screen">
                <RadioGroup v-model="searchRadio">
                  <Radio label="">全部</Radio>
                  <Radio label="0">已发布</Radio>
                  <Radio label="1">草稿</Radio>
                </RadioGroup>
              </FormItem>
              <!--              <FormItem label="创建时间">-->
              <!--                <Row>-->
              <!--                  <Col span="5">-->
              <!--                    <DatePicker v-model="searchFormItem.dateRange" format="yyyy/MM/dd" type="daterange"-->
              <!--                                placement="bottom-end" placeholder="请选择日期" style="width: 213px"></DatePicker>-->
              <!--                  </Col>-->
              <!--                </Row>-->
              <!--              </FormItem>-->
              <!--              <FormItem label="上传者">-->
              <!--                <Input v-model="searchFormItem.upLoader" placeholder="请输入"/>-->
              <!--              </FormItem>-->
              <!--              <FormItem label="正确率区间">-->
              <!--                <div style="display: inline-flex;justify-content: space-between;align-items: flex-start">-->
              <!--                  <Input style="width: 45%" v-model="searchFormItem.correctRateMin" placeholder="请输入"/>-->
              <!--                  <div>~</div>-->
              <!--                  <Input style="width: 45%" v-model="searchFormItem.correctRateMax" placeholder="请输入"/>-->
              <!--                </div>-->
              <!--              </FormItem>-->
              <FormItem style="display: flex" class="search-button">
                <Button class="filter-btn" type="primary" @click="clearSearch" style="margin-right: 10px;width: 100px;">清空</Button>
                <Button class="filter-btn" type="primary" @click="searchQuestion((searchAll = false), true)" style="width: 100px;">搜索</Button>
              </FormItem>
            </Form>
          </div>
        </div>
        <img style="cursor: pointer" v-if="!showMore" src="../../../../assets/组505@2x.png" width="16" height="16" @click="showMore = true" />
        <img style="cursor: pointer" v-if="showMore" src="../../../../assets/组558@2x.png" width="16" height="16" @click="showMore = false" />
      </div>
    </div>
    <!-- ******************************************************************************** -->
    <div class="table-container bg-shadow" v-if="!showMore">
      <!-- ******************************* 表格&分页*************************************** -->
      <Table
        class="container"
        @on-row-click="viewDetail"
        :loading="loading"
        ref="selectTable"
        stripe
        :columns="tableHeader"
        :data="questionTableData"
        @on-selection-change="getSelectedIds"
        :max-height="tableH"
      >
        <template slot-scope="{ row, index }" slot="stem">
          <!--          <span v-html="row.stem"></span>-->
          <Tooltip placement="top" transfer style="width: 100%" max-width="100%">
            <div slot="content" :style="{ 'max-height': tableH - 150 + 'px', overflow: 'auto' }">
              <div v-html="row.stem"></div>
            </div>
            <pre v-html="row.stem" class="nowrap" style="overflow: hidden;max-width: 100%;max-height: 48px" />
          </Tooltip>
        </template>
        <template slot-scope="{ row, index }" slot="questionType">
          <span>{{ questionType[row.questionType].label }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="knowledgePoints">
          <el-tooltip v-if="row.knowledgePoints && row.knowledgePoints.length !== 0">
            <div slot="content">
              <span v-for="(k, i) in row.knowledgePoints" :key="`k-${i}`">{{ k.knowledgeName }}<br /></span>
            </div>
            <div>
              <span v-for="(k, i) in row.knowledgePoints" :key="i">
                {{ k.knowledgeName }}
                {{ i === row.knowledgePoints.length - 1 ? '' : ', ' }}
              </span>
            </div>
          </el-tooltip>
          <div v-else>
            无
          </div>
        </template>
        <template slot-scope="{ row, index }" slot="difficulty">
          <span>{{ difficulty[row.difficulty].label }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="expectedTime">
          <span>{{ row.expectedTime }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="useCount">
          <span>{{ row.useCount }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="status">
          <span>{{ questionStatus[row.status] }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="createdTime">
          <span>{{ row.createdTime }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="modifyTime">
          <span>{{ row.updatedTime }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="upLoader">
          <span>{{ row.authorName === 'unknown' ? '超级管理员' : row.authorName }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="operation">
          <div class="operation">
            <Tooltip content="添加到题库" placement="top" style="margin-right: 10px">
              <svg class="icon spread operaIcon" aria-hidden="true" @click.stop="addQuestionToBank(row)">
                <use xlink:href="#icon-xinzeng" />
              </svg>
            </Tooltip>
            <Tooltip content="修改" placement="top" style="margin-right: 10px">
              <svg class="icon spread operaIcon" aria-hidden="true" @click.stop="modifyQuestions(row.questionId, row)">
                <use xlink:href="#icon-bianji" />
              </svg>
            </Tooltip>
            <Tooltip content="测试用例" placement="top" style="margin-right: 10px">
              <svg class="icon spread operaIcon" aria-hidden="true" @click.stop="resolveTestCase(row.questionId)">
                <use xlink:href="#icon-wj-khb" />
              </svg>
            </Tooltip>
            <Tooltip content="删除" placement="top" style="margin-right: 10px">
              <svg class="icon spread operaIcon" aria-hidden="true" @click.stop="deleteQuestion(row.questionId)">
                <use xlink:href="#icon-shanchu" />
              </svg>
            </Tooltip>
          </div>
        </template>
      </Table>
      <!--      <div style="margin: 10px">-->
      <!--        <div style="float: right;">-->
      <!--          <Page-->
      <!--            show-sizer-->
      <!--            show-elevator-->
      <!--            :page-size-opts="[10,20,40,100]"-->
      <!--            @on-page-size-change="changeSize"-->
      <!--            :total="parseInt(`${this.questionTotalCount}`)"-->
      <!--            :current="page + 1"-->
      <!--            show-total-->
      <!--            @on-change="changePage"-->
      <!--          />-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
    <div style="position: relative">
      <div class="more" v-if="showMore">
        <div class="left">
          <div class="top">
            <div style="display: flex;align-items: center">
              <Checkbox :value="indeterminate" v-if="!showGrid" @on-change="handleSelectAll" />
              <div class="name">题目</div>
              <RadioGroup v-model="detailRadio">
                <Radio label="全部" />
                <Radio label="草稿" />
                <Radio label="已发布" />
                <Radio label="无知识点" />
              </RadioGroup>
            </div>
            <img style="cursor: pointer" v-if="showGrid" src="../../../../assets/组611@2x.png" width="16" height="16" @click="showGrid = false" />
            <Icon v-if="!showGrid" type="ios-menu" size="20" style="cursor: pointer" @click="showGrid = true" />
          </div>
          <Table
            @on-row-click="row => (selectId = row.questionId)"
            highlight-row
            @on-selection-change="getSelectedIds"
            v-if="!showGrid"
            :height="tableH - 20"
            ref="selection"
            :data="questionTableData"
            :columns="columns"
            :show-header="false"
          >
            <template slot-scope="{ row }" slot="name">
              <Tooltip placement="top" transfer style="width: 100%" max-width="100%">
                <div
                  slot="content"
                  :style="{
                    'max-height': tableH - 150 + 'px',
                    overflow: 'auto'
                  }"
                >
                  <div v-html="row.stem"></div>
                </div>
                <div style="display:flex;max-width: 350px;overflow: hidden">
                  <div class="type">
                    {{ questionType[row.questionType].label.substring(0, 1) }}
                  </div>
                  <pre v-html="row.stem" class="nowrap" style="max-height: 48px;overflow: hidden" />
                </div>
              </Tooltip>
            </template>
            <template slot-scope="{ row }" slot="status">
              <Badge :color="row.status === 0 ? '#C6DDF5' : '#E2E2E2'" :text="row.status === 0 ? '已发布' : '草稿'" />
            </template>
          </Table>
          <div v-if="showGrid" class="indicator-grid-container" :style="{ height: tableH - 10 + 'px' }">
            <div
              @click="selectId = item.questionId"
              :style="{
                background: selectId === item.questionId ? 'rgba(106,138,190,1)' : '#C6DDF5'
              }"
              class="item"
              v-for="(item, index) in questionTableData"
              :key="index"
            >
              <div class="q">
                {{ questionType[item.questionType].label.substring(0, 1) }}
              </div>
              <div style="color: #1A3C72;font-size: 14px;margin-top: -4px">
                {{ index + 1 }}
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <question-edit :questionId="selectId" :tags="tags" :knowledge-point-tree="knowledgePointTree" />
        </div>
      </div>
      <Spin size="large" fix v-if="spinShow" />
    </div>
    <QuestionRepoAddNew
      :value="visible"
      ref="addQuestion"
      @on-change-visible="visible = false"
      :isQuestionAdd="isQuestionAdd"
      :questionBankIds="questionBankIds"
      :tags="tags"
      :page="page"
      :size="size"
      :modifyQuestionId="modifyQuestionId.toString()"
      @changeShowAddQuestionModal="changeShowAddQuestionModal"
      @refreshQuestionList="refreshQuestionList"
      :knowledge-point-tree="knowledgePointTree"
    >
    </QuestionRepoAddNew>
    <Page
      class="yt-page"
      :class="'yt-page-' + (this.questionTotalCount.length > 1 ? this.questionTotalCount.length : 2)"
      show-sizer
      show-elevator
      :page-size-opts="[10, 20, 40, 100]"
      @on-page-size-change="changeSize"
      :total="parseInt(`${this.questionTotalCount}`)"
      :current="page + 1"
      show-total
      @on-change="changePage"
    />
    <ConfirmDialog :value="showDeleteConfirm" :title="'删除题目'" @on-ok="clickDelete" @on-cancel="deleteClose" @on-cancel-end="afterDeleteClose">
      <div style="width: 219px; margin: 0 auto;">
        <YTIcon :href="'#icon-jingshi'" style="font-size: 18px"></YTIcon>
        删除题目后，使用了该题目的试卷与练习还保留该题的快照,你还要继续吗？
      </div>
    </ConfirmDialog>
    <!--    <DeleteModal-->
    <!--      v-model="showDeleteConfirm"-->
    <!--      :title="`确认要删除选中的题目吗？`"-->
    <!--      @on-ok="clickDelete"-->
    <!--    >-->
    <!--      <span class="error">-->
    <!--        删除题目后，使用了该题目的试卷与练习还保留该题的快照,你还要继续吗？-->
    <!--      </span>-->
    <!--    </DeleteModal>-->
    <QuestionDetailModal :value="showDetail" ref="showDetail" @changeShowDetail="changeShowDetail" />
    <Modal v-model="showMoveBand" title="移动到新题库" :mask-closable="false" @on-ok="moveConfirm" @on-cancel="moveCancel" class="moveModal">
      <div style="text-align: center">已选中{{ selectedQuestionNum }}道题目</div>
      <card style="min-height: 350px">
        <!--        <Input class="search-bar" search placeholder="请输入查找信息" v-model="searchContent" style="margin-bottom: 5px"/>-->
        <Select v-model="selectQuestionBankId">
          <Option
            v-for="(id, index) in questionBankName"
            :key="index"
            :value="id.questionBankId"
            v-html="id.name"
            @click="getBankName(id.name, id.questionBankId)"
          />
        </Select>
      </card>
    </Modal>
    <QuestionInputModal :value="showInputQuestion" :questionBankIds="questionBankIds" @changeShowInput="changeShowInput" />
    <Modal v-model="showOutputQuestion" title="批量导出题目" :mask-closable="false" @on-ok="outputConfirm">
      <div style="text-align: center">已选中{{ selectedQuestionNum }}道题目,确认要导出吗？</div>
    </Modal>
    <Modal v-model="showAddToBank" title="添加到题库" :mask-closable="false" @on-ok="addConfirm" @on-cancel="moveCancel" class="moveModal">
      <div style="text-align: center">已选中{{ selectedQuestionNum }}道题目</div>
      <card dis-hover style="min-height: 350px">
        <Select class="search-bar" placeholder="请输入查找信息" v-model="searchContent" @on-change="getBankName(searchContent)">
          <Option v-for="(id, index) in questionBankName" :value="id.questionBankId" :key="index">{{ id.name }}</Option>
        </Select>
      </card>
      <Select v-model="selectedKnowledgePoints" multiple>
        <Option v-for="(item, index) in knowledgePoint" :key="index + 0" :value="item.id"> {{ index + 1 }}.{{ item.name }} </Option>
      </Select>
    </Modal>
    <TestCaseMaintainModal v-model="showTestCaseModal" ref="testcase-modal" />
    <Modal @on-cancel="cancelSetK" title="批量设置知识点" v-model="setKnowledge" :width="1432" class="kModal">
      <div style="display: flex" :style="{ height: tableH + 'px' }">
        <div class="modalL" style="width: 422px">
          <div class="top">
            <div>题目</div>
            <Icon @click="selectQuestions = []" type="ios-trash-outline" style="font-size: 22px;cursor: pointer" />
          </div>
          <Table :max-height="tableH - 62" ref="selection" :data="selectQuestions" :columns="modalColumns" :show-header="false">
            <template slot-scope="{ row }" slot="name">
              <Tooltip placement="top" transfer max-width="500">
                <div
                  slot="content"
                  :style="{
                    'max-height': tableH - 150 + 'px',
                    overflow: 'auto'
                  }"
                >
                  <div v-html="row.stem"></div>
                </div>
                <div style="display: flex">
                  <div class="type">
                    {{ questionType[row.questionType].label.substring(0, 1) }}
                  </div>
                  <pre style="width: 320px;overflow: hidden;max-height: 48px" v-html="row.stem" class="nowrap" />
                </div>
              </Tooltip>
            </template>
            <template slot-scope="{ row }" slot="op">
              <Icon
                @click="
                  selectQuestions.splice(
                    selectQuestions.findIndex(q => q.questionId === row.questionId),
                    1
                  )
                "
                type="ios-trash-outline"
                style="font-size: 22px;cursor: pointer"
              />
            </template>
          </Table>
        </div>
        <div class="modalR">
          <div class="left">
            <Tag
              style="margin: 0 8px 10px 0"
              @on-close="closeTag(index)"
              color="#E7EFF7"
              v-for="(item, index) in modalKnowledges"
              :key="index"
              closable
              >{{ item.name }}</Tag
            >
            <Button
              @click="clearTag(false)"
              v-if="modalKnowledges.length !== 0"
              shape="circle"
              style="height: 22px;line-height: 22px;color: #5E6470;font-size: 12px;margin-top: -8px"
              >清空</Button
            >
          </div>
          <div class="right">
            <div class="top">知识点</div>
            <Input ref="kInput" placeholder="请输入关键字" v-model="filterText" style="margin: 8px 0 0 8px;width: 334px" />
            <div
              :style="{
                height: tableH - 124 + 'px',
                overflow: 'auto',
                padding: '0 12px 12px 12px',
                'margin-top': '12px'
              }"
            >
              <el-tree
                :filter-node-method="filterNode"
                node-key="id"
                ref="modalTree"
                @check-change="val => handleCheckChange(val, false)"
                :data="knowledgePointTree"
                :props="{ label: 'name' }"
                check-strictly
                show-checkbox
              />
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="foot">
        <Button @click="clearAllK" style="margin-right: 4px" :loading="clearLoading">清除知识点</Button>
        <Button @click="cancelSetK">取消</Button>
        <Button
          @click="confirmSetK"
          :disabled="selectQuestions.length === 0 || modalKnowledges.length === 0"
          type="primary"
          :loading="knowledgeLoading"
          >确认</Button
        >
      </div>
    </Modal>
    <Drawer
      title="知识点"
      v-model="showDrawer"
      :mask="false"
      @on-close="
        showDrawer = false
        filterText = ''
      "
    >
      <Input ref="searchKInput" placeholder="请输入关键字" v-model="filterText" style="margin: 0 0 8px 8px;width: 220px" />
      <div>
        <el-tree
          :filter-node-method="filterNode"
          node-key="id"
          ref="sModalTree"
          @check-change="val => handleCheckChange(val, true)"
          :data="knowledgePointTree"
          :props="{ label: 'name' }"
          check-strictly
          show-checkbox
        />
      </div>
    </Drawer>
  </div>
</template>
<script>
import questionApi from '../../../../api/questionRepo.js'
import QuestionAddNewModal from './question_handle/QuestionAddNewModal'
import QuestionDetailModal from './question_handle/QuestionDetailModal'
import QuestionInputModal from './question_handle/QuestionInputModal'
import { difficulty } from '../../../../util/difficulty.js'
import { questionType } from '../../../../util/questionType.js'
import { mapState } from 'vuex'
import DeleteModal from '../../../common/DeleteModal'
import questionBankApi from '../../../../api/question.js'
import { throttle } from '@util/throttle'
import config from '../../../../config/config.js'
import TestCaseMaintainModal from './question_handle/TestCaseMaintainModal'
import QuestionEdit from '../../../manage/question/bank/QuestionEdit'
import knowledge from '@api/knowledge'
import ConfirmDialog from '../../../common/dialog/ConfirmDialog'
import YTIcon from '../../../common/YTIcon'

export default {
  name: 'QuestionBankDetail',
  methods: {
    showDraw() {
      this.showDrawer = true
      this.$nextTick(() => {
        this.$refs.searchKInput.focus()
      })
    },
    clearAllK() {
      this.$Modal.confirm({
        title: '提示',
        content: '<p>确认清除所选题目的所有知识点吗？</p>',
        loading: true,
        onOk: () => {
          this.clearLoading = true
          let q = this.selectQuestions.map(item => {
            return item.questionId
          })
          questionApi
            .clearK(q)
            .then(res => {
              if (this.showMore) {
                this.getMoreData()
              } else {
                this.getAllQuestion(this.page, this.size, this.sort)
              }
              this.cancelSetK()
              this.$message.success('清除成功')
              this.clearLoading = false
            })
            .catch(() => {
              this.clearLoading = false
            })
          this.$Modal.remove()
        }
      })
    },
    clearSearch() {
      this.$refs['searchForm'].resetFields()
      this.searchRadio = ''
      this.filterText = ''
      this.$refs.sModalTree.setCheckedNodes([])
      if (this.showMore) {
        this.getMoreData()
      } else {
        this.searchQuestion((this.searchAll = false), true)
      }
      this.isSpread = false
    },
    showSetK() {
      if (this.selectedQuestionIds.length === 0) {
        this.$message.error('您还未选择题目')
      } else {
        this.setKnowledge = true
        this.$nextTick(() => {
          this.$refs.kInput.focus()
        })
      }
    },
    getTags() {
      questionApi.getTags().then(res => {
        this.tags = res.res
      })
    },
    confirmSetK() {
      let q = this.selectQuestions.map(item => {
        return item.questionId
      })
      let k = this.modalKnowledges.map(item => {
        return item.id
      })
      this.knowledgeLoading = true
      questionApi
        .addKnowledge(q, k)
        .then(res => {
          if (this.showMore) {
            this.getMoreData()
          } else {
            this.getAllQuestion(this.page, this.size, this.sort)
          }
          this.cancelSetK()
          this.knowledgeLoading = false
          this.$message.success('设置成功')
        })
        .catch(() => {
          this.knowledgeLoading = false
        })
    },
    cancelSetK() {
      this.clearTag(false)
      this.selectQuestions = []
      this.filterText = ''
      this.setKnowledge = false
      if (this.showMore) {
        this.$refs.selection.selectAll(false)
      } else {
        this.$refs.selectTable.selectAll(false)
      }
    },
    clearTag(search) {
      if (search) {
        this.searchFormItem.knowledgePoints = []
        this.$refs.sModalTree.setCheckedNodes([])
      } else {
        this.modalKnowledges = []
        this.$refs.modalTree.setCheckedNodes([])
      }
    },
    closeTag(index, search) {
      if (search) {
        this.searchFormItem.knowledgePoints.splice(index, 1)
        this.$refs.sModalTree.setCheckedNodes(this.searchFormItem.knowledgePoints)
      } else {
        this.modalKnowledges.splice(index, 1)
        this.$refs.modalTree.setCheckedNodes(this.modalKnowledges)
      }
    },
    handleCheckChange(data, search) {
      if (search) {
        this.searchFormItem.knowledgePoints = this.$refs.sModalTree.getCheckedNodes()
      } else {
        this.modalKnowledges = this.$refs.modalTree.getCheckedNodes()
      }
    },
    getKnowledgeTree() {
      knowledge.getFullKnowledgeTree().then(res => {
        this.knowledgePointTree = res.res.filter(item => {
          if (item.status === false) {
            return item
          }
        })
      })
    },
    handleSelectAll(status) {
      this.$refs.selection.selectAll(status)
    },
    deleteClose() {
      this.showDeleteConfirm = false
    },
    viewDetail(row) {
      this.$refs.showDetail.getData(row.questionId)
      this.showDetail = true
    },
    addQuestionToBank(row) {
      this.selectedKnowledgePoints = []
      this.selectedQuestionIds = row.questionId
      this.selectedQuestionNum = 1
      this.showAddToBank = true
    },
    addToBank() {
      this.selectedKnowledgePoints = []
      this.selectedQuestionNum = this.selectedQuestionIds.length
      if (this.selectedQuestionNum === 0) {
        this.$message.error('您还未选择任何题目')
      } else {
        this.showAddToBank = true
      }
    },
    addConfirm() {
      const bankId = this.questionBankId
      if (!bankId) {
        return this.$message.warning('请选择题库')
      }
      const payload = {
        copyQuestionIds: this.selectedQuestionIds,
        knowledgePointIds: this.selectedKnowledgePoints,
        toQuestionBankId: bankId
      }
      questionApi.copyQuestion(payload).then(() => {
        this.$message.success('添加成功')
      })
    },
    inputOutputQuestion(name) {
      if (name === 'input') {
        this.showInputQuestion = true
      } else {
        this.showOutputQuestion = true
        this.selectedQuestionNum = this.selectedQuestionIds.length
        if (this.selectedQuestionNum === 0) {
          this.showOutputQuestion = false
          this.$message.error('您还未选中任何题目')
        }
      }
    },
    outputConfirm() {
      window.location.href = config.baseUrl + `/question/api/v1/questions/download/excel?questionIds=${this.selectedQuestionIds}`
    },
    moveQuestion() {
      this.selectedQuestionNum = this.selectedQuestionIds.length
      if (this.selectedQuestionNum === 0) {
        this.$message.error('您还未选中任何题目')
      } else {
        this.showMoveBand = true
      }
    },
    getBankName(id) {
      this.questionBankId = id
    },
    searchBank() {
      this.$Loading.start()
      const payload = {
        searchContent: ''
      }
      questionBankApi
        .getOpenQuestionBanks('', 0, 999, payload)
        .then(res => {
          this.questionBankName = []
          this.selectQuestionBankId = res.res.data[0].questionBankId
          this.questionBankId = this.selectQuestionBankId
          for (let i = 0; i < res.res.data.length; i++) {
            this.questionBankName.push(res.res.data[i])
          }
        })
        .finally(() => {
          this.$Loading.finish()
        })
    },
    moveConfirm() {
      questionApi.moveQuestions(this.selectQuestionBankId, this.selectedQuestionIds).then(() => {
        this.$message.success('移动成功')
        if (this.showMore) {
          this.getMoreData()
        } else {
          this.getAllQuestion(this.page, this.size, this.sort)
        }
      })
    },
    moveCancel() {
      this.searchContent = ''
      this.selectedQuestionNum = ''
    },
    getAllQuestion(page, size, isSearch, list) {
      this.loading = true
      if (list) {
        this.spinShow = true
      }
      let payload = this.isSearchTrueOrFalse(isSearch)
      questionApi
        .searchAdvanced(this.$route.params.id, page, size, payload)
        .then(res => {
          this.questionTableData = res.res.data.map(item => {
            item.stem = this.cleanWord(item.stem)
            return item
          })
          // this.initQuestionTableData = res.data.res.data.map(item => {
          //   item.stem = this.cleanWord(item.stem)
          //   return item
          // })
          if (this.questionTableData.length === 0) {
            this.selectId = ''
          } else {
            this.selectId = this.questionTableData[0].questionId
          }
          // this.questionTableData.map(item => {
          //   this.setStemTextFormat(item.stem)
          // })
          // this.questionTotalCount = parseInt(res.res.total)
          this.questionTotalCount = res.res.total
          this.loading = false
          if (list) {
            this.spinShow = false
          }
        })
        .catch(() => {
          this.loading = false
          if (list) {
            this.spinShow = false
          }
        })
    },
    // setStemTextFormat(stem) {
    //   return stem.replace(/<(?!img).*?>/g, '')
    // },
    modifyQuestions(id, row) {
      this.modifyQuestionId = id
      this.isQuestionAdd = false
      questionApi.getQuestionInfo(id).then(res => {
        this.modifyForm.modifyQuestionType = res.res.questionType
        this.modifyForm.modifyQuestionDifficulty = res.res.difficulty
        this.modifyForm.modifyQuestionExpectedTime = res.res.expectedTime
        this.modifyForm.modifyQuestionStem = res.res.stem
        this.modifyForm.modifyQuestionAnswer = res.res.answer
        this.modifyForm.modifyQuestionAnalysis = res.res.analysis
        this.modifyForm.modifyQuestionOptions = res.res.options
        this.modifyForm.modifyQuestionBlanks = res.res.blanks
        this.modifyForm.status = res.res.status
        this.modifyForm.modifyQuestionCodeTemplate = Object.assign([], res.res.codeQuestionTemplates)
        this.modifyForm.modifyQuestionKnowledgePoints = res.res.knowledgePoints || []
        this.modifyForm.tags = res.res.tags
        this.$refs.addQuestion.getData(this.modifyForm)
        this.visible = true
      })
    },
    getSelectedIds(data) {
      this.selectQuestions = data
      this.selectedQuestionIds = []
      this.selectedKnowledgePoints = []
      for (let i = 0; i < data.length; i++) {
        this.selectedQuestionIds.push(data[i].questionId)
        this.selectedKnowledgePoints.push(data[i].knowledgePoints)
      }
    },
    deleteQuestion(id) {
      this.deleteStatus = 0
      this.showDeleteConfirm = true
      this.deleteQuestionId = id
    },
    deleteQuestions() {
      this.deleteStatus = 1
      this.showDeleteConfirm = true
      if (this.selectedQuestionIds.length === 0) {
        this.showDeleteConfirm = false
        this.$message.error('您还未选中任何题目')
      }
    },
    resolveTestCase(questionId) {
      this.$refs['testcase-modal'].setQuestionId(questionId)
      this.showTestCaseModal = true
    },
    clickDelete() {
      if (this.deleteStatus === 0) {
        questionApi.deleteQuestion(this.deleteQuestionId).then(res => {
          this.showDeleteConfirm = false
          if (this.$route.query.keyword === undefined) {
            this.getAllQuestion(this.page, this.size)
          } else {
            this.searchByKeyword()
          }
          if (res.code === 0) {
            this.$message.success('删除成功')
          }
        })
      } else {
        questionApi.deleteQuestions(this.selectedQuestionIds).then(() => {
          this.showDeleteConfirm = false
          if (this.$route.query.keyword === undefined) {
            if (this.showMore) {
              this.getMoreData()
            } else {
              this.getAllQuestion(this.page, this.size)
            }
          } else {
            this.searchByKeyword()
          }
          if (res.code === 0) {
            this.$message.success('删除成功')
          }
        })
      }
    },
    handleOpen() {
      this.searchVisible = !this.searchVisible
    },
    changePage(changePageNum) {
      this.page = changePageNum - 1
      this.getAllQuestion(changePageNum - 1, this.size, this.searchAll)
    },
    changeSize(val) {
      this.size = val
      this.getAllQuestion(this.page, this.size, this.searchAll)
    },
    addNewQuestion() {
      this.isQuestionAdd = true
      this.visible = true
    },
    changeShowAddQuestionModal(arg) {
      this.visible = arg
    },
    changeShowDetail(arg) {
      this.showDetail = arg
    },
    changeShowInput(arg) {
      this.showInputQuestion = arg
    },
    getMonth(obj) {
      if (obj < 10) return '0' + obj
      else return obj
    },
    getDate(obj) {
      if (obj < 10) return '0' + obj
      else return obj
    },
    getTime() {
      if (this.searchFormItem.dateRange !== '') {
        let startDay = this.searchFormItem.dateRange[0]
        let endDay = this.searchFormItem.dateRange[1]
        this.startTime = startDay.getFullYear() + '/' + this.getMonth(startDay.getMonth() + 1) + '/' + this.getDate(startDay.getDate())
        this.endTime = endDay.getFullYear() + '/' + this.getMonth(endDay.getMonth() + 1) + '/' + this.getDate(endDay.getDate())
      }
    },
    isSearchTrueOrFalse(isSearch) {
      let ids = this.searchFormItem.knowledgePoints.map(item => {
        return item.id
      })
      let payload = {
        authorName: isSearch ? null : this.searchFormItem.upLoader,
        difficulty: isSearch ? null : this.searchFormItem.difficulty,
        endTime: isSearch ? null : this.endTime,
        keyWord: this.searchFormItem.keyWord,
        questionTagIds: isSearch ? null : this.searchFormItem.questionTag,
        knowledgePointIds: isSearch ? null : ids,
        lowerRate: isSearch ? null : this.searchFormItem.correctRateMin,
        questionType: isSearch ? null : this.searchFormItem.questionType,
        startTime: isSearch ? null : this.startTime,
        upperRate: isSearch ? null : this.searchFormItem.correctRateMax,
        status: this.searchRadio === '' ? null : Number(this.searchRadio)
      }
      return payload
    },
    clearSearchQuestion() {
      // 通过关键词搜索题库数据
      this.searchFormItem.keyWord = this.keyWord
      let searchAll = false
      if (this.keyWord === '') {
        searchAll = true
      }
      this.searchQuestion(searchAll)
    },
    searchQuestion(isSearch) {
      if (this.showMore) {
        this.getMoreData()
      } else {
        let payload = this.isSearchTrueOrFalse(isSearch)
        this.loading = true
        questionApi
          .searchAdvanced(this.questionBankIds, 0, this.size, payload)
          .then(res => {
            this.page = 0
            this.questionTableData = res.res.data.map(item => {
              item.stem = this.cleanWord(item.stem)
              return item
            })
            // if (this.searchRadio === '已发布') {
            //   this.questionTableData = this.initQuestionTableData.filter(item => {
            //     return item.status === 0
            //   })
            // } else {
            //   this.questionTableData = this.initQuestionTableData.filter(item => {
            //     return item.status === 1
            //   })
            // }
            if (this.questionTableData.length === 0) {
              this.selectId = ''
            } else {
              this.selectId = this.questionTableData[0].questionId
            }
            this.questionTotalCount = parseInt(res.res.total)
            this.loading = false
            this.isSpread = false
          })
          .catch(() => {
            this.loading = false
          })
      }
    },
    searchByKeyword(page, size) {
      this.loading = true
      questionApi
        .searchQuestion(page, size, this.$route.query.keyword)
        .then(res => {
          this.questionTableData = res.res.data.map(item => {
            item.stem = this.cleanWord(item.stem)
            return item
          })
          this.initQuestionTableData = res.res.data
          if (this.questionTableData.length === 0) {
            this.selectId = ''
          } else {
            this.selectId = this.questionTableData[0].questionId
          }
          this.questionTotalCount = parseInt(res.res.data.total)
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    getMoreData() {
      this.spinShow = true
      let payload = this.isSearchTrueOrFalse(true)
      payload.hasKnowledge = this.hasKnowledge ? null : false
      questionApi
        .getBriefStem(this.$route.params.id, payload)
        .then(res => {
          this.questionTableData = res.res.map(item => {
            item.stem = this.cleanWord(item.stem)
            return item
          })
          if (this.questionTableData.length === 0) {
            this.selectId = ''
          } else {
            this.selectId = this.questionTableData[0].questionId
          }
          this.spinShow = false
        })
        .catch(() => {
          this.spinShow = false
        })
    },
    filterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    getSearchTags() {},
    refreshQuestionList() {
      this.getAllQuestion(this.page, this.size, this.sort)
    }
  },
  computed: {
    ...mapState({
      knowledgePoint: state => state.knowledge.rootKnowledgeList
    }),
    indeterminate() {
      if (this.selectedQuestionIds.length === this.questionTableData.length) {
        return true
      } else {
        return false
      }
    }
  },
  created() {
    this.getKnowledgeTree()
    this.getTags()
    this.getSearchTags()
    if (this.$route.query.keyword === undefined) {
      this.getAllQuestion(this.page, this.size, this.sort)
    } else {
      this.fromSearch = true
      this.searchByKeyword(0, 10)
    }
    this.questionBankIds = this.$route.params.id
    this.currentTab = this.$route.query.tab
    this.$store.dispatch('knowledge/getRootKnowledgeList')
    this.searchBank()
    this.$watch(
      'searchContent',
      throttle(newQuery => {
        this.$emit('searchContent', newQuery)
        this.searchBank()
      })
    )
  },
  watch: {
    filterText(val) {
      this.$refs.modalTree.filter(val)
      this.$refs.sModalTree.filter(val)
    },
    detailRadio(val) {
      switch (val) {
        case '全部':
          this.searchRadio = ''
          this.hasKnowledge = true
          break
        case '无知识点':
          this.searchRadio = ''
          this.hasKnowledge = false
          break
        case '已发布':
          this.searchRadio = '0'
          this.hasKnowledge = true
          break
        case '草稿':
          this.searchRadio = '1'
          this.hasKnowledge = true
          break
      }
      if (this.showMore && !this.isSpread) {
        this.getMoreData()
      }
    },
    searchRadio(val) {
      switch (val) {
        case '':
          this.detailRadio = '全部'
          break
        case '0':
          this.detailRadio = '已发布'
          break
        case '1':
          this.detailRadio = '草稿'
          break
      }
    },
    showMore(val) {
      this.questionTableData = []
      this.selectedQuestionIds = []
      if (val) {
        this.getMoreData()
      } else {
        this.page = 0
        this.size = 10
        this.getAllQuestion(0, 10, this.sort)
      }
    },
    showGrid() {
      this.selectedQuestionIds = []
    }
  },
  data() {
    return {
      showDrawer: false,
      clearLoading: false,
      filterText: '',
      hasKnowledge: true,
      tags: [],
      searchRadio: '',
      initQuestionTableData: [],
      modalKnowledges: [],
      selectQuestions: [],
      knowledgeLoading: false,
      setKnowledge: false,
      knowledgePointTree: [],
      spinShow: false,
      selectId: '',
      modalColumns: [
        {
          title: '',
          slot: 'name',
          width: 360,
          className: 'stem-column'
        },
        {
          title: '',
          slot: 'op',
          width: 55
        }
      ],
      columns: [
        {
          type: 'selection',
          width: 25,
          align: 'center'
        },
        {
          title: '',
          slot: 'name',
          width: 350,
          ellipsis: true
        },
        {
          title: '',
          slot: 'status'
        }
      ],
      detailRadio: '全部',
      showGrid: true,
      showMore: false,
      tableH: document.body.clientHeight - 250,
      isSpread: false,
      keyWord: '',
      searchAll: false,
      file: '',
      selectedKnowledgePoints: [],
      currentTab: '',
      questionBankId: '',
      searchContent: '',
      selectQuestionBankId: '',
      questionBankName: [],
      startTime: '',
      endTime: '',
      loading: false,
      knowledge: [],
      modifyForm: {
        modifyQuestionType: 0,
        modifyQuestionDifficulty: '',
        modifyQuestionKnowledgePoints: [],
        modifyQuestionExpectedTime: 0,
        modifyQuestionStem: '',
        modifyQuestionOptions: [],
        modifyQuestionBlanks: [],
        modifyQuestionAnswer: '',
        modifyQuestionAnalysis: '',
        tags: []
      },
      modifyQuestionId: '',
      deleteQuestionId: 0,
      showDeleteConfirm: false,
      selectedQuestionIds: [],
      selectedQuestionNum: '',
      deleteStatus: 0,
      index: 1,
      isQuestionAdd: true,
      page: 0,
      size: 10,
      sort: '',
      questionBankIds: '',
      questionTotalCount: 0,
      tableHeader: [
        {
          type: 'selection',
          width: 65,
          className: 'select-column',
          ellipsis: false
        },
        {
          title: '题干',
          slot: 'stem',
          className: 'stem-column'
        },
        {
          title: '题型',
          slot: 'questionType',
          className: 'question-type-column'
        },
        {
          title: '知识点',
          slot: 'knowledgePoints',
          className: 'knowledge-points-column',
          ellipsis: true
        },
        {
          title: '难度',
          slot: 'difficulty',
          className: 'difficulty-column'
        },
        {
          title: '用时(分钟)',
          slot: 'expectedTime',
          className: 'expected-time-column'
        },
        {
          title: '使用次数',
          slot: 'useCount',
          className: 'use-count-column'
        },
        {
          title: '状态',
          slot: 'status',
          className: 'correct-rate-column'
        },
        {
          title: '创建时间',
          slot: 'createdTime',
          className: 'create-time-column'
        },
        {
          title: '修改时间',
          slot: 'modifyTime',
          className: 'modify-time-column'
        },
        {
          title: '上传者',
          slot: 'upLoader',
          className: 'uploader-column'
        },
        {
          title: '操作',
          slot: 'operation',
          className: 'operation-column'
        }
      ],
      questionTableData: [],
      searchVisible: false,
      visible: false,
      searchFormItem: {
        questionTag: [],
        keyWord: null,
        questionType: null,
        difficulty: null,
        dateRange: null,
        knowledgePoints: [],
        upLoader: null,
        correctRateMin: null,
        correctRateMax: null
      },
      difficulty: difficulty,
      questionType: questionType,
      showDetail: false,
      showMoveBand: false,
      showInputQuestion: false,
      showAddToBank: false,
      showOutputQuestion: false,
      showTestCaseModal: false,
      fromSearch: false,
      questionStatus: ['已发布', '草稿', '待审核']
    }
  },
  components: {
    QuestionEdit,
    // Select,
    QuestionRepoAddNew: QuestionAddNewModal,
    DeleteModal,
    QuestionDetailModal,
    QuestionInputModal,
    TestCaseMaintainModal,
    ConfirmDialog,
    YTIcon
  }
}
</script>
<style lang="less" scoped>
@import '../../../../theme/variables';
::v-deep .ivu-table-cell {
  max-height: 48px !important;
  overflow: hidden;
}
.kModal {
  ::v-deep {
    .ivu-modal-body {
      padding: 0 50px;
    }
  }
  .foot {
    ::v-deep .ivu-btn {
      height: 36px;
    }
    ::v-deep .ivu-btn-primary {
      background-color: #6a8abe;
      border-color: #6a8abe;
      margin-left: 14px;
      &:hover {
        background-color: #6a8abe;
        border-color: #6a8abe;
      }
    }
  }
  .modalR {
    flex: auto;
    background: rgba(255, 255, 255, 1);
    border: 3px solid rgba(234, 237, 241, 1);
    opacity: 1;
    border-radius: 3px;
    margin-left: 10px;
    display: flex;
    .left {
      flex: auto;
      border-right: 2px solid #eaedf1;
      padding: 20px;
      ::v-deep .ivu-tag {
        font-size: 14px;
        font-weight: 400;
        height: 30px;
        line-height: 30px;
        .ivu-tag-text {
          color: #415e8d !important;
        }
        .ivu-icon {
          color: #415e8d !important;
        }
      }
    }
    .right {
      width: 350px;
      .top {
        height: 56px;
        padding: 0 16px;
        color: #333333;
        font-size: 16px;
        font-weight: 400;
        border-bottom: 2px solid #eaedf1;
        display: flex;
        align-items: center;
      }
    }
  }
  .modalL {
    width: 482px;
    background: rgba(255, 255, 255, 1);
    border: 3px solid rgba(234, 237, 241, 1);
    opacity: 1;
    border-radius: 3px;
    .top {
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 16px;
      color: #333333;
      font-size: 16px;
      font-weight: 400;
      border-bottom: 2px solid #eaedf1;
    }
  }
}
.type {
  min-width: 22px;
  height: 22px;
  background: rgba(212, 225, 245, 1);
  opacity: 1;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #415e8d;
  font-size: 12px;
  font-weight: 400;
  margin-right: 4px;
  margin-top: 10px;
}
.more {
  display: flex;
  margin-top: 20px;
  .left {
    background: white;
    margin-right: 20px;
    width: 482px;
    min-width: 482px;
    height: 100%;
    box-shadow: 0px 0px 5px rgba(7, 27, 41, 0.1);
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 18px;
      color: #3a4e64;
      font-size: 14px;
      font-weight: 400;
      border-bottom: 2px solid rgba(234, 237, 241, 1);
      .name {
        color: #333333;
        font-size: 16px;
        margin-right: 8px;
        margin-top: -2px;
      }
    }
    .indicator-grid-container {
      display: grid;
      align-self: center;
      grid-template-columns: repeat(9, 1fr);
      grid-auto-rows: 45px;
      grid-gap: 9px;
      padding: 14px;
      overflow: auto;
      .item {
        width: 38px;
        height: 38px;
        opacity: 1;
        border-radius: 3px;
        cursor: pointer;
        .q {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 16px;
          height: 15px;
          background: rgba(124, 162, 226, 1);
          border-radius: 3px 0px 3px 0px;
          font-size: 12px;
          font-weight: 400;
          color: rgba(255, 255, 255, 1);
        }
      }
    }
  }
  .right {
    flex: auto;
  }
}
::v-deep .ivu-dropdown-item {
  width: 120px;
}

.question-container {
  display: flex;
  flex-direction: column;
  padding: 10px 20px 20px 20px;
  height: 100%;
  background-color: @layout-body-background;
  overflow: auto;

  .search-container {
    background-color: white;
    padding: 10px 15px;
    margin: 10px 0 0 0;
    box-shadow: 0 0 5px 0 rgba(7, 27, 41, 0.1);
    display: flex;
    justify-content: space-between;

    .top-btnContainer {
      display: flex;
      align-items: center;

      .filter-btn {
        margin-right: 10px;
      }
    }

    .search-bar {
      float: right;
      width: 330px;

      ::v-deep .ivu-input {
        border: 0;
        border-bottom: 1px solid #ccc;
        border-radius: 0;

        &:focus {
          box-shadow: 0 1px 0 rgba(45, 140, 240, 0.2);
        }
      }
    }

    .search-advanced {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 1%;

      .advanced-container {
        flex: 1 0 auto;
        cursor: pointer;
        margin-left: 10px;
        font-size: 14px;
        padding: 5px 10px;

        svg.icon {
          transition: all @default-transition;
          transform: rotate(0deg);
          margin-left: 8px;
        }

        svg.icon.spread {
          transform: rotate(180deg);
        }
      }

      .advanced-board {
        position: absolute;
        z-index: 99;
        top: 179px;
        right: 20px;
        width: 330px;
        border-right: solid 5px @primary-color;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        transition: all @default-transition;
        overflow: auto;

        &.show {
          height: auto;
        }

        &.unshow {
          height: 400px;
          display: none;
        }
        .search {
          margin-right: 20px;
          height: 40px;
          border-radius: 4px;
        }

        .form {
          width: 300px;
          margin-top: 20px;
        }
      }

      /*::v-deep .ivu-select-dropdown {*/
      /*  left: auto !important;*/
      /*  right: 10px;*/
      /*}*/
    }
  }
}

::v-deep .w-e-text-container {
  height: 150px !important;
}

.search-dropdown {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 470px;

  .search-button {
    button {
      margin-right: 20px;
      min-width: 100px;
    }
    ::v-deep .ivu-form-item-content {
      margin: auto !important;
      text-align: center;
    }
  }
}

.page-style {
  margin-top: -30px;
  z-index: 100;
  text-align: left;

  ::v-deep .ivu-page-total {
    margin-left: 19px;
  }
}

.table-container {
  width: 100%;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
}
.question-container ::v-deep .flex-table {
  border-bottom: 1px solid #e8eaec;

  .select-column {
    width: 35px;
  }
  .stem-column {
    width: 15%;
    /*flex: 1 1 auto;*/
    overflow: hidden;
  }

  .question-type-column {
    width: 70px;
  }

  .knowledge-points-column {
    width: 12%;
    min-width: 80px;
  }

  .difficulty-column {
    width: 60px;
  }

  .expected-time-column {
    min-width: 35px;
  }

  .use-count-column {
    width: 90px;
  }

  .correct-rate-column {
    width: 80px;
  }

  .create-time-column {
    width: 100px;
  }

  .modify-time-column {
    width: 100px;
  }

  .uploader-column {
    width: 5%;
    min-width: 80px;
  }

  .operation-column {
    width: 90px;

    .operation {
      cursor: pointer;
      color: @primary-color;
      transition: all @default-transition;

      &:hover {
        color: @primary-hover-color;
      }
    }
  }
}
.bankName {
  padding: 3px;
  border-radius: 6px;
  &:hover {
    background-color: #e8eaec;
  }
}
.moveModal ::v-deep .ivu-modal-body {
  padding: 0 20px;
}
::v-deep .el-tree {
  .el-tree-node__content {
    display: block !important;
  }
  .el-tree-node__children {
    overflow: visible !important;
  }
}
.kInput {
  cursor: pointer;
  min-height: 50px;
  width: 100%;
  background: rgba(255, 255, 255, 1);
  border: 2px solid rgba(234, 237, 241, 1);
  opacity: 1;
  border-radius: 4px;
  padding: 0 8px;

  ::v-deep .ivu-tag {
    font-size: 12px;
    font-weight: 400;
    height: 26px;
    line-height: 26px;

    .ivu-tag-text {
      color: #415e8d !important;
    }

    .ivu-icon {
      color: #415e8d !important;
    }
  }
}
</style>
