<template>
  <el-dialog title="删除" :visible="visible" width="484" :close-on-click-modal="false" @close="close" class="yt-dialog yt-dialog-confirm">
    <div class="confirm-header">
      <div>
        <svg class="icon delete-icon" aria-hidden="true">
          <use xlink:href="#icon-jingshi" />
        </svg>
      </div>
      <div class="modal-right">
        <div class="title">
          {{ title }}
        </div>
      </div>
    </div>
    <div class="content">
      <slot />
    </div>
    <div class="footer" style="margin-top: 10px">
      <el-button size="small" @click="close" class="el-button--small">取消</el-button>
      <el-button size="small" type="primary" class="el-button--small el-button--primary" :loading="loading" @click="ok">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '确认删除？'
    }
  },
  data: function() {
    return {
      visible: this.value
    }
  },
  methods: {
    close() {
      this.visible = false
      this.$emit('input', false)
      this.$emit('on-cancel')
    },
    ok() {
      this.$emit('on-ok')
    }
  },
  watch: {
    value(val) {
      this.visible = val
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../theme/variables';

.delete-icon {
  font-size: 40px;
  fill: @caution-color;
  margin-right: 20px;
}
.confirm-header {
  display: flex;
  align-items: center;
  font-size: 24px;
  margin-left: 79px;
}
.content {
  margin-left: 139px;
  margin-right: 70px;
  margin-top: 20px;
  font-size: @medium;
  color: #555555;
}
</style>
