import config from '../config/config'
import { axios } from './index'

// const baseUrl = 'http://192.168.0.100:8096'
const baseUrl = `${config.baseUrl}/judge/api/v1`
export default {
  /**
   * 获取某个题目下的所有测试用例
   * @param questionId
   * @returns {AxiosPromise<any>}
   */
  getTestCasesById(questionId) {
    return axios.get(`${baseUrl}/testcase/get/all?id=${questionId}`)
  },

  /**
   * 批量存储测试用例
   * @param payload
   * @returns {AxiosPromise<any>}
   */
  saveTestCase(payload) {
    return axios.post(`${baseUrl}/testcase/save`, payload)
  },
  /**
   * 根据测试用例 id 删除测试用例
   * @param tcId
   */
  deleteTestcaseById(tcId) {
    return axios.post(`${baseUrl}/testcase/delete?id=${tcId}`)
  }
}
